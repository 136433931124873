import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import CourseReview from '../apis/CourseReview';

const UpdateCourse = (props) => {
    const {id} = useParams();
    let history = useHistory();
    const [name, setName] = useState("")
    const [specialty, setSpecialty] = useState("")
    const [provider, setProvider] = useState("")


    useEffect(()=> {
        const fetchData = async () => {
            const response = await CourseReview.get(`https://physiocr.ca/api/v1/courses/${id}`)
            setName(response.data.data.course.name)
            setSpecialty(response.data.data.course.specialty)
	    setProvider(response.data.data.course.provider)

        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault()
        await CourseReview.put(`https://physiocr.ca/api/v1/courses/${id}`, {
            name,
            specialty,
	    provider,
        });
        history.push("/")
    }
    return (
        <div>
            <form action="">
            <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input 
                    value = {name}
                    onChange = {e => setName(e.target.value)}
                    id ="name" 
                    type="text" 
                    className="form-control"
                    />
                </div>

		<div className="form-group">
	        <label htmlFor="provider">Provider</label>
	        <input 
	        value = {provider}
	        onChange = {e => setProvider(e.target.value)}
	        id ="name" 
	        type="text" 
	        className="form-control"
	        />
	       </div>

                <div className="form-group">
                    <label htmlFor="specialty">Specialty</label>
                    <input 
                    value = {specialty}
                    onChange = {e => setSpecialty(e.target.value)}
                    id ="name" 
                    type="text" 
                    className="form-control"
                    />
                </div>

                <button
                className="btn btn-primary"
                onClick={handleSubmit}
                >Submit</button>
            </form>
        </div>
    )
}

export default UpdateCourse

