import React, {useEffect,useContext} from 'react';
import CourseReview from "../apis/CourseReview";
import { CoursesContext } from '../context/CoursesContext';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useState } from 'react';

const CourseSearch = () => {
    const handleMatch = (text) => {
        for (let i = 0; i < courses.length; i++) {
   
            if (text === courses[i].name) {
                const trueCourseID = courses[i].id
                history.push(`/courses/${trueCourseID}`)
                return trueCourseID
            }
            
        }

    }

    const [searchText, setSearchText] = useState("");

    const {courses, setCourses} = useContext(CoursesContext);
  let history = useHistory();



  useEffect(() => {
    const fetchData = async ()=> {
        try {
            const response = await CourseReview.get("/");
            // console.log(response.data.data.courses);
            setCourses(response.data.data.courses);
    } catch (err) {console.log(err)}
    };
    
    fetchData();
       // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

    return (
        <div>
            <Autocomplete
            options={courses}
            getOptionLabel={(option) => option.name}
            // fullWidth
            autoComplete
            onChange={e => setSearchText(handleMatch(e.target.textContent))}
            renderInput={(params) => 
            <TextField {...params} 
            label="Search for a course review here" 
            variant="filled"
            value={searchText}
            />}
            />
            </div>
    )
}

export default CourseSearch
