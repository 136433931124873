import React from 'react'
import Header from '../components/Header'
import BrowseAllCourses from '../components/BrowseAllCourses'

const BrowseAllCoursesPage = () => {
    return (
        <div style={{width:'100%'}}>
            <Header/>
            <BrowseAllCourses/>
        </div>
    )
}

export default BrowseAllCoursesPage
