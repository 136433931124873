import React from 'react'
import Header from '../components/Header'
import { useHistory } from 'react-router-dom'
import CourseReview from '../apis/CourseReview';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    cardAction: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    title: {
      fontSize: 14,
    },
  });
  
const ReviewList = ({data}) => {
    const classes = useStyles();
    let history = useHistory();

    const handleDelete = async (e, id) => {
        e.stopPropagation()
        try {
           await CourseReview.delete(`https://physiocr.ca/api/v1/courses/${id}/deleteReview`)
            history.go(0)
        } catch (err) {
            console.log(err)
        }

    }

    const pulledReviewData= data.data.data.reviews
    return (
        <div>
            <Header/>
        <h2>
            {pulledReviewData.length} Reviews
        </h2>
    {pulledReviewData.map((review) => {
    return (
      <Box key={review.id}
      m={5}>
<Card className={classes.root}>
  <CardContent>
    <CardHeader title= {<Typography className={classes.title} color="textSecondary" gutterBottom>
      Review by {review.name}, {review.timestamp}
    </Typography>}
    />
    <Grid container spacing={3}>
    <Grid item xs={12} sm ={6}>
      <Box p={1}>
      {/* <h1>Review Text</h1> */}
      <Typography>
        {review.review}
      </Typography>
    </Box>
    </Grid>
    <Grid item xs={12} sm ={6}>
        <Box p={1}>
        {/* <h1>Scores</h1> */}
        <Typography
        variant="h6"
        >
            Overall Impression: {review.rating}/5
            </Typography>

            <Typography>

            <br></br>                
            Instructor(s): {review.instructor}/5
            <br>
            </br>
            Course Content: {review.content}/5
            <br>
            </br>
            Value for Money: {review.value}/5
            <br>
            </br>
            Clinical Application: {review.clinical_application}/5
            <br>
            </br>
      </Typography>

    </Box>
    </Grid>
    </Grid>
  </CardContent>
  <CardActions disableSpacing
  className={classes.cardAction}>
        <IconButton
         onClick={ (e) => handleDelete(e, review.id)}
         >
            <DeleteIcon color="primary"/>
        </IconButton>
      </CardActions>
</Card>
</Box>
    )
  })}
        </div>
    )
}

export default ReviewList
