import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Header from '../components/Header';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: 'center',
        // height: "100vh",
        fontFamily: "Nunito",
      },
    rootCard: {
        minWidth: 275,
        marginBottom: "2em"
      },
  title: {
    fontSize: 14,
    fontFamily: "Nunito",
  },
  pos: {
    marginBottom: 12,
  },
});



const AboutPage = () => {

    const classes = useStyles();

    return (
      <div className="container-fluid">

        <div>
            <Fade in={true} style={{ transitionDelay: '500ms'}}>
        <div className={classes.root}>
            <Header/>
            <Card className={classes.rootCard}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          About PTCR:
        </Typography>
        <Typography variant="body2" component="p">
          Courses are expensive.
          <br />
          <br/>
          The goal of PTCR is to create a community where physiotherapists can honestly critique and
          rate courses they have taken. This can better inform other therapists if the courses they
          are interested in are right for them before signing up.
        </Typography>
      </CardContent>
    </Card>


    <Card className={classes.rootCard}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Privacy Policy and Terms of Use:
        </Typography>
        <Typography variant="body2" component="p">
          PTCR does not store any data except for the reviews you submit.
          <br/>
          <br/>
          PTCR is meant to be a place to view professional, honest, and critical feedback from physiotherapists
          who have taken postgraduate courses. Reviews not meeting standards (eg. inappropriate language)
          may be removed.
          <br/>
          <br/>
          By using this website, you hereby consent to the Privacy Policy and
          agree to its Terms and Conditions.

        </Typography>
      </CardContent>
    </Card>


    </div>
    </Fade>
        </div>
        </div>

    )
}

export default AboutPage
