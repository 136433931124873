import React from 'react'
import CourseList from '../components/CourseList'
import Header from '../components/Header'
import { useHistory } from "react-router-dom"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useState } from 'react';



const EditCourses = () => {
    let history = useHistory()

    const [checkPW, setCheckPW ] = useState("")
    const [submittedPW, setSubmittedPW ] = useState(false)


    const handleSubmit = () => {
        if (checkPW === `<u^MG6xp2ze{uX}"`) {
            setSubmittedPW(true)
    } else {
        history.push('/')
    }
}

    return (
        <div>
            <Header/>

            <TextField 
            required 
            id="standard-basic" 
            label="Password"
            helperText="You shouldn't be here"
            onChange={(e)=>setCheckPW(e.target.value)}
             />
             <Button
            //  type="submit"
             onClick={handleSubmit}
             >
                 Submit
             </Button>

            {submittedPW && <CourseList/>}
            {/* <CourseList/> */}
        </div>
    )
}

export default EditCourses
