import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles({
    root: {
      minWidth: '80%',
    },
    title: {
      fontSize: '1rem',
    },
  });
  

const ReviewCards = ({reviews}) => {
    const classes = useStyles();
    return (
        <div className={classes.root} style={{ width: '100%' }}>

      {reviews.map((review) => {
        return (
          <Box key={review.id}
          mb={5} mt={1}>
 <Card className={classes.root}>
      <CardContent>
        <CardHeader title= {<Typography className={classes.title} color="textSecondary" gutterBottom>
          Review by {review.name}, {review.timestamp}
        </Typography>}
        />
        <Grid container spacing={3}>
        <Grid item xs={12} sm ={6}>
          <Box p={1}>
          {/* <h1>Review Text</h1> */}
          <Typography
          style={{whiteSpace:'pre-line'}}>
            {review.review}
          </Typography>
        </Box>
        </Grid>
        <Grid item xs={12} sm ={6}>
            <Box p={1}>
            {/* <h1>Scores</h1> */}
            <Typography
            variant="h6"
            >
                Overall Impression: {review.rating}/5
                </Typography>

                <Typography>

                <br></br>                
                Instructor(s): {review.instructor}/5
                <br>
                </br>
                Course Content: {review.content}/5
                <br>
                </br>
                Value for Money: {review.value}/5
                <br>
                </br>
                Clinical Application: {review.clinical_application}/5
                <br>
                </br>
          </Typography>
        </Box>
        </Grid>
        </Grid>
      </CardContent>
    </Card>
    </Box>
        )
      })}
          
    </div>
    )
}

export default ReviewCards
