import React, { useState } from 'react'
import UpdateCourse from '../components/UpdateCourse'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const UpdatePage = () => {


    let history = useHistory();
    const [checkPW, setCheckPW ] = useState("")
    const [submittedPW, setSubmittedPW ] = useState(false)


    const handleSubmit = () => {
        if (checkPW === `<u^MG6xp2ze{uX}"`) {
            setSubmittedPW(true)
    } else {
        history.push('/')
    }
}


    return (
        <div>
            <h1 className="text-center">Update Course</h1>

            <TextField 
            required 
            id="standard-basic" 
            label="Password"
            helperText="You shouldn't be here"
            onChange={(e)=>setCheckPW(e.target.value)}
             />
             <Button
            //  type="submit"
             onClick={handleSubmit}
             >
                 Submit
             </Button>

            {submittedPW && <UpdateCourse/>}
        </div>
    )
}

export default UpdatePage;