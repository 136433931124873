import React from 'react'
// import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';


const Header = () => {
    const useStyles = makeStyles((theme) => ({
        emphasis: {
            color: 'red',
        },
        headerText: {
            textAlign: 'center',
            fontSize: '5rem',
            // textDecoration: 'none',
            color: '#fff'
        },
        "&:hover":{
            color: '#fff'
        },
        "a" :{
            color: "inherit"
        }
      }));

      const classes = useStyles();

    // let history = useHistory();
    // const handleClick = () => {
    //     history.push(`/`)
    // }

    return (
        <div id="back-to-top-anchor"
        style={{
            textAlign: "center",
        }}
        >
            {/* <button
            style={{
                backgroundColor: 'transparent',
                border: 'none',
                outline: 'none',
                alignContent: 'center',
                textAlign: 'center',
                fontSize: '12vh',
                WebkitAppearance: 'none'
            }}
            >
                <p 
                onClick={() => handleClick()}
                className={classes.headerText}>PT 
                <span className={classes.emphasis}>CR</span>.
                </p>   
            </button> */}


    <Typography className={classes.headerText}>
      <Link component={RouterLink} to="/" color="inherit" underline="none" variant="inherit">
        <span style={{color:'#fff'}}>PT</span><span style={{color:'red'}}>CR</span><span style={{color:'#fff'}}>.</span>
      </Link>
    </Typography>

        </div>
    )
}

export default Header;
