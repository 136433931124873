import React from 'react'
import AddCourse from '../components/AddCourse'
import Header from '../components/Header'
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';


const useStyles = makeStyles((theme) => ({
input: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    marginTop: "1rem",
    },
root :{
  fontFamily: "Nunito",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // height: "70vh",
  marginTop: "5rem",
},
  }));

const Home = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
          <div>
          <Header/>
          </div>
            <Grow in={true}>
            <div className={classes.input}>
            <AddCourse/>
            </div>
            </Grow>
        </div>
    )
}

export default Home;