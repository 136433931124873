import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CourseReview from '../apis/CourseReview'
import { useHistory, useParams } from "react-router-dom"
import Confirm from '../components/Confirm'


const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      flexWrap:'wrap',
    },
    ratingDropdown: {
        width: '25ch',
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        
      },
      textField: {
        width: '25ch',
      },
      buttons: {
        margin: theme.spacing(2),
        display: 'flex',
      },
    // '& > *': {
    //   margin: theme.spacing(1),
    //   width: '80ch',
    // },
    // selection: {
    // '& .MuiTextField-root': {
    //     margin: theme.spacing(1),
    //     width: '25ch',
    // },
    // },
}));

const AddReview = () => {
    const classes = useStyles();
    const history = useHistory();
    // const location = useLocation();
    const { id } = useParams();
    const [name, setName] = useState("");
    const [review, setReview] = useState("");
    const [rating, setRating] = useState("");
    const [instructorRating, setInstructorRating] = useState("");
    const [contentRating, setContentRating] = useState("");
    const [valueRating, setValueRating] = useState("");
    const [applicationRating, setApplicationRating] = useState("");
    const [timeStamp, setTimeStamp] = useState("");
    // const [confirmHandler, setConfirmHandler] = useState(false)
    // const [clickEvent, setClickEvent] = useState("")

    // const allowConfirmDialog = (e) => {
    //   e.stopPropagation()
    //   console.log('checking if state is not empty string')
    //   if (applicationRating!==""){
    //     setConfirmOpen(true)
    //   }
    //   // confirmHandler ? setConfirmOpen(true) : setConfirmOpen(false)
    // }

    const primaryHandler = (e) => {
      e.preventDefault()
         if (
        name ==="" || 
        review ==="" ||
        rating ==="" ||
        instructorRating ==="" ||
        contentRating ==="" ||
        valueRating ==="" ||
        applicationRating ===""
      ) {return} else setConfirmOpen(true);
  }
    const handleSubmitReview = async (e) => {
            // e.preventDefault();

        try {
          await CourseReview.post(`https://physiocr.ca/api/v1/courses/${id}/addReview`, {
          name,
          review,
          rating,
          instructor: instructorRating,
          content: contentRating,
          value: valueRating,
          clinical_application: applicationRating,
          timestamp: timeStamp
        });
        history.go(0);
        } catch (err) {
          
        }
        
    }


      let date = new Date();
       let postDate = date.toLocaleDateString(
        'en-gb',
        {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })

        const [confirmOpen, setConfirmOpen] = useState(false)    

    return (
        <div>
            <div className={classes.root}>                
            
            <form autoComplete="off"
            // onSubmit={handleSubmitReview}
            >
            <TextField 
            onClick={()=>{setTimeStamp(postDate)}}
            required 
            inputProps={{
              maxLength: 20,
            }}
            id="standard-basic" 
            label="Name"
            helperText="Name or initials"
            value = {name}
            className={classes.textField}
            onChange={e => setName(e.target.value)}

             />
            <TextField
            required
          id="standard-full-width"
          label="Review"
          helperText="Please enter a brief description of your experience with this course"
          multiline
          inputProps={{ maxLength: 2000 }}
          rowsMax={5}
          value={review}
          onChange={e => setReview(e.target.value)}
          margin="normal"
        fullWidth
        />
           
        

<TextField
          id="standard-select-rating"
          select
          required
          className={classes.ratingDropdown}
          label="Instructor Rating"
          value={instructorRating}
          onChange={e => setInstructorRating(e.target.value)}

        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </TextField>

        <TextField
          id="standard-select-rating"
          select
          required
          className={classes.ratingDropdown}
          label="Content Rating"
          value={contentRating}
          onChange={e => setContentRating(e.target.value)}

        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </TextField>

        <TextField
          id="standard-select-rating"
          select
          required
          className={classes.ratingDropdown}
          label="Value for Money Rating"
          value={valueRating}
          onChange={e => setValueRating(e.target.value)}

        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </TextField>

        <TextField
          id="standard-select-rating"
          select
          required
          className={classes.ratingDropdown}
          label="Clinical Application Rating"
          value={applicationRating}
          onChange={e => setApplicationRating(e.target.value)}

        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </TextField>

        <TextField
          id="standard-select-rating"
          select
          required
          className={classes.ratingDropdown}
          label="Overall Impression"
          value={rating}
          onChange={e => setRating(e.target.value)}

        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </TextField>
        <Box>
        <Button
                    type="submit"
                    size="large"
                    className={classes.buttons}
                    color="secondary"
                    onClick={(e)=>primaryHandler(e)}
                    >
                Submit
                </Button>
                <Confirm
                        title="Are you sure you want submit this review?"
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={handleSubmitReview}
                    >
                        Please ensure that all fields are completed and 
                        you are satisfied with your comment and ratings. Note: Comments with
                        unprofessional behaviour (eg. swearing) will be removed.
                        <br/><br/>
                        You will not be able to edit or delete this post after submitting.
                    </Confirm>
                </Box>
            </form>



            </div>
        </div>
    )
}

export default AddReview
