import React from 'react'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import Home from "./routes/Home";
import AboutPage from "./routes/AboutPage";
import Coursedetails from "./routes/Coursedetails";
import UpdatePage from "./routes/UpdatePage";
import { CoursesContextProvider } from './context/CoursesContext';
// import Header from './components/Header';
import NotFound from './components/NotFound';
import AddCoursesPage from './routes/AddCoursesPage'
import EditCoursesPage from './routes/EditCourses';
import EditReviewsPage from './routes/EditReviewsPage';
// import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import BrowseAllCoursesPage from './routes/BrowseAllCoursesPage';
// import Brightness4Icon from '@material-ui/icons/Brightness4';
// import { useState } from 'react';
// import Button from '@material-ui/core/Button';

// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';

// import { useHistory } from 'react-router-dom'


const App = () => {

    // const [darkMode, setDarkMode] = useState(false)

    const theme = createMuiTheme({
        palette: {
            primary: {
              main: '#d32f2f',
            },
            type: "dark",
		background: {
			default: '#131516',
			paper: '#303030',
		}
            // type: darkMode ? "dark" : "light"
          },
          typography:{
            fontFamily: "Nunito"
          }
      });

      // const useStyles = makeStyles((theme) => ({
      //   darkBtn: {
      //       border: 'none',
      //       outline: 'none',
      //       backgroundColor: 'transparent',
      //       alignSelf: 'flex-end'
      //   },
      //   root: {
      //       flexGrow: 1,
      //     },
      //     menuButton: {
      //       marginRight: theme.spacing(2),
      //     },
      //     title: {
      //       flexGrow: 1,
      //       fontFamily: "Nunito",
      //       fontSize: "8vh",
      //       textDecoration: "none"
      //     },
      //     test: {
      //       color: "red",
      //     },
      // }));

      // const classes = useStyles();
      // let history = useHistory();
      // console.log(history)
      // const handleClick = () => {
      //     history.push(`/`)
      // }
    return (
    <ThemeProvider theme={theme}>
    <CssBaseline>
    <CoursesContextProvider>

    {/* <div className={classes.root}>
        {console.log(history)}
      <AppBar 
      style= {{
          
      }}
      position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h2" className={classes.title}>
              <a 
              className= {classes.title}
              href="/">
                <Button
                className={classes.title}
                >
                PT
                <span className={classes.test}>
                CR 
                </span>
                .
                </Button>
              </a>
          
          </Typography>          
          <Button>
              <Brightness4Icon
    className={classes.darkBtn}
    onClick={() => setDarkMode(!darkMode)}
    style={{ fontSize: 30 }}
    />
          </Button>
          

        </Toolbar>
      </AppBar>
    </div> */}

    <div className="container"
    style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }}>
    
        <Router>
            <Switch>
            <Route exact path="/courses/addcourse" component ={AddCoursesPage}/>
            <Route exact path="/courses/editcourses" component ={EditCoursesPage}/>
            <Route exact path="/" component ={Home}/>
            <Route exact path="/about" component ={AboutPage}/>
            <Route exact path="/courses/:id" component ={Coursedetails}/>
            <Route exact path="/courses/:id/update" component ={UpdatePage}/>
            <Route exact path="/courses/:id/editreviews" component ={EditReviewsPage}/>
            <Route exact path="/browse" component ={BrowseAllCoursesPage}/>
           <Route component ={NotFound} /> 
            </Switch>
        </Router>
    </div>
        </CoursesContextProvider>
        </CssBaseline>
        </ThemeProvider>       

    );
}

export default App;
