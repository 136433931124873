import Header from '../components/Header'
import CourseSearch from '../components/CourseSearch'
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Nunito",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100vh",
    //marginTop:'5rem'
  },
  bottomText: {
    marginTop: "8em",
    textAlign: "center"
  },
  aboutText: {
    textAlign: "center",
    textTransform:"none"    
  },
  emphasis: {
    color: 'red',
},
  welcomeText: {
    textAlign: "center",
  }

}));

const Home = () => {

  const history = useHistory();
  const handleAddClick = () => {
    history.push('/courses/addcourse')
  }

  const handleAboutClick = () => {
    history.push('/about')
  }

  const handleBrowseAll = () => {
    history.push('/browse')
  }
  const classes= useStyles();
  
  return (
    <div className={classes.root}>
      <Fade 
        in={true}
        style={{ transitionDelay: '400ms'}}>
          
          <div className={classes.welcomeText}>
          <Header/>

            <h2 className={classes.welcomeText}>
            Welcome to PT <span className={classes.emphasis}>Course Reviews</span>
          </h2>
            <CourseSearch/>          
          <div className={classes.bottomText}>
            <div>
              <Button
              onClick={handleBrowseAll}
              >
                View All Courses
              </Button>
            </div>
            <div>
	   <Typography>
	              Can't find a course? 	&nbsp;
	              <Link
	              underline="none"
	              onClick={handleAddClick}
	              >
	     <span style={{
	     color: 'red',
	     cursor:'pointer'
	     }}>
	     Add one here.
	    </span>
	    </Link>
	    </Typography>

              </div>
              <div className={classes.aboutText}>
                <Button
                onClick={handleAboutClick}
                color="primary"
                className={classes.aboutText}
                >
                  About PTCR.
                </Button>
              </div>
          </div>
          </div>
        </Fade>
    </div>
  )
}

export default Home
