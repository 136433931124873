import React from 'react'
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import CourseReview from '../apis/CourseReview';
import Header from '../components/Header';
import StarRating from '../components/StarRating';
import { CoursesContext } from '../context/CoursesContext';
import { makeStyles } from '@material-ui/core/styles';
import ReviewCards from '../components/ReviewCards';
import AddReview from '../components/AddReview';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import ScrollToTop from '../components/ScrollToTop'
import { useHistory } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    container: {
      flexGrow: 1,
      fontFamily: "Nunito",
      display: "flex",
    //   height: "100%",
      minHeight: "100vh",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

const Coursedetails = () => {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    let history = useHistory();

const renderRating = (data) => {
    if (data.course.count === null) {
        return (
            <>
            <span>No reviews yet! Be the first to add a review.</span>
            </>
        )
    } else {
        return (
        <>
    <StarRating rating = {data.course.average_rating} size = "large" />
    <span> Based on {data.course.count} review(s)</span>
</>
    )
    }
}

    const handleChange = () => {
      setChecked((prev) => !prev);
    };
 
    const {id} = useParams()
    const {selectedCourse, setSelectedCourse} = useContext(CoursesContext)
    const [checkSort, setCheckSort] = useState(true)
    const [oldestNewest, setOldestNewest] = useState('Oldest')

    useEffect(() => {
        
        const fetchData = async () => {
            try {
            const response = await CourseReview.get(`https://physiocr.ca/api/v1/courses/${id}`)
            setSelectedCourse(response.data.data)
		//console.log(response);
		if (!response.data.data.course){
                history.push('/404')
            } else
            setSelectedCourse(response.data.data)
            } catch (err) {
                console.log(err)
             //   history.push('/404')

            }
        }
        fetchData();
           // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const sorter = (array) => {
        checkSort ? setOldestNewest('Most Recent') : setOldestNewest('Oldest')
        setCheckSort(!checkSort)
       let test = [...array.reviews].reverse()
        setSelectedCourse({ ...selectedCourse, reviews:test})
    }
	
	window.scroll(0,0);

    return (
        <div className="container">
        <div className={classes.container}>
            {selectedCourse && (
                <>
                <Fade 
                in={true}
                style={{ transitionDelay: '400ms'}}>
                    <div className="container">
                    <Header/>
                   <h1>{selectedCourse.course.name}</h1>
                   {selectedCourse.course.provider && (<h6>Provider: {selectedCourse.course.provider}</h6>)}

		   <br/>
		    {renderRating(selectedCourse)}
                    <br></br>
                    <Button 
                    onClick={handleChange}
                    >
                    Add Review
                    </Button>
                    
                    <Collapse in={checked}>
                <AddReview/>
            </Collapse>
            <div style={{
                display: 'flex',
                flexDirection: 'row-reverse'
            }}>
              <Button
                onClick={()=>sorter(selectedCourse)}
            >
                Sorted by date: {oldestNewest}
            </Button>  
            </div>
            
            <ReviewCards reviews={selectedCourse.reviews}/>
                    </div>
                    </Fade>
                    <ScrollToTop/>
                    
            
                </>
            )}   
        
        </div>
        </div>

    )
}

export default Coursedetails;
