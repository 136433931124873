import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import ReviewList from '../components/ReviewList'
import { useEffect } from 'react';
import CourseReview from '../apis/CourseReview';
import { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';




const EditReviewsPage = () => {

    let history = useHistory();
    const {id} = useParams()
    const [data, setData] = useState('')

    const [checkPW, setCheckPW ] = useState("")
    const [submittedPW, setSubmittedPW ] = useState(false)


    const handleSubmit = () => {
        if (checkPW === `<u^MG6xp2ze{uX}"`) {
            setSubmittedPW(true)
    } else {
    //    history.push('/')
    }
}


    useEffect(() => {
        const fetchData = async () => {
            try {
            const response = await CourseReview.get(`https://physiocr.ca/api/v1/courses/${id}`)
            // console.log(response)
            setData(response)
            // setSelectedCourse(response.data.data)
            } catch (err) {
                console.log(err)
                history.push('/404')

            }
        }
        fetchData();
           // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    

    return (
        <div>
            <TextField 
            required 
            id="standard-basic" 
            label="Password"
            helperText="You shouldn't be here"
            onChange={(e)=>setCheckPW(e.target.value)}
             />
             <Button
            //  type="submit"
             onClick={handleSubmit}
             >
                 Submit
             </Button>
            {submittedPW && data && (
                <>
        <ReviewList data={data}/>
            </>
            )}
            </div>

    )
}

export default EditReviewsPage
