import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import CourseReview from "../apis/CourseReview"
import { CoursesContext } from '../context/CoursesContext'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom"
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Confirm from '../components/Confirm'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap:'wrap',
      },
      ratingDropdown: {
          marginBottom: theme.spacing(2),
          marginTop: theme.spacing(2),
          
        },
  }));

const AddCourse = () => {
    const history = useHistory();
    const classes = useStyles();
    const {addCourses} = useContext(CoursesContext)
    const [name, setName] = useState("")
    const [specialty, setSpecialty] = useState("")
    const [provider, setProvider] = useState("")
    const [confirmOpen, setConfirmOpen] = useState(false)    


    const primaryHandler = (e) => {
        if (specialty==="" || name ==="" || provider==="") {return} else handleSubmit(e);
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            
            const response = await CourseReview.post("https://physiocr.ca/api/v1/courses", {
                name: name,
                specialty: specialty,
		provider: provider,
            });
            addCourses(response.data.data.course);
            history.push(`/courses/${response.data.data.course.id}`)
        } catch (err) {
            console.log(err)
        }
        // history.push(`/courses/${response.data.data.course.id}`)
    }


      
    return (
        <div>
            <div>
            <form 
            style={{
                display:"flex",
                flexDirection:"column"
            }}
            // onSubmit={handleSubmit}
            autoComplete="off">
                <TextField
                    onChange= {(e) => {setName(e.target.value)}} 
                    required
                    id="standard-basic" 
                    label="Course name"
                    variant="filled"
                    helperText="Please enter the full name as displayed in the course syllabus or manual"
                />


	    <TextField
	     onChange= {(e) => {setProvider(e.target.value)}} 
	     required
	     value={provider}
	     id="standard-basic" 
	     label="Course provider"
	     variant="filled"
	     helperText="Please enter the name of the instructor(s) or organization that provides this course"
	      />

        <TextField
          id="standard-select-rating"
          select
          required
          className={classes.ratingDropdown}
          label="Course Specialty"
          value={specialty}
          onChange={e => setSpecialty(e.target.value)}

        >
          <MenuItem value={'Acupuncture'}>Acupuncture</MenuItem>
          <MenuItem value={'Animal Rehab'}>Animal Rehab</MenuItem>
          <MenuItem value={'Cardiorespiratory'}>Cardiorespiratory</MenuItem>
          <MenuItem value={'Geriatric'}>Geriatric</MenuItem>
          <MenuItem value={'Global Health'}>Global Health</MenuItem>
          <MenuItem value={'Leadership'}>Leadership</MenuItem>
          <MenuItem value={'Neurosciences'}>Neurosciences</MenuItem>
          <MenuItem value={'Oncology'}>Oncology</MenuItem>
          <MenuItem value={'Orthopedic'}>Orthopedic</MenuItem>
          <MenuItem value={'Pediatric'}>Pediatric</MenuItem>
          <MenuItem value={'Pain Science'}>Pain Science</MenuItem>
          <MenuItem value={'Pelvic Health'}>Pelvic Health</MenuItem>
          <MenuItem value={'Private Practice'}>Private Practice</MenuItem>
          <MenuItem value={'Sport'}>Sport</MenuItem>
          <MenuItem value={'Other'}>Other</MenuItem>
        </TextField>

                    <Button 
                    size="large"
                    type="submit"
                    // onClick={handleSubmit}
                    // onClick={()=>setConfirmOpen(true)}
                    onClick={(e)=>primaryHandler(e)}
                    >
                    Add Course
                    </Button>

                    <Confirm
                        title="Are you sure you want add this course?"
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={primaryHandler}
                    >
                        Please ensure the course name is spelled correctly and 
                        the appropriate specialty is selected.
                        <br/><br/>
                        You will not be able to edit or delete this course after submitting.
                    </Confirm>                    
        </form>
        </div>
        {/* <Button onClick={()=>{console.log(specialty)}}>what specialty</Button>
        <Button onClick={()=>{console.log(name)}}>what name</Button> */}
        </div>
    )
}

export default AddCourse
