import React from 'react'
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const StarRating = ({ rating, size }) => {

    const stars = [];
    for (let i = 1; i <=5; i++){
        if (i <= rating) {
            stars.push(<StarIcon key={i} fontSize= {size} />)
        } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
            stars.push(<StarHalfIcon key={i} fontSize= {size} />)
        } else {
            stars.push(<StarBorderIcon key={i}  fontSize= {size} />)
        }
    }  

    return (
        <>
            {stars}
        </>
    )
}

export default StarRating
