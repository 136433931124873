import React, {useEffect,useContext } from 'react';
import CourseReview from "../apis/CourseReview";
import { CoursesContext } from '../context/CoursesContext';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import { useState } from 'react';
import StarRating from './StarRating';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';




const BrowseAllCourses = () => {

    const {courses, setCourses} = useContext(CoursesContext);


    let history = useHistory();
    useEffect(() => {
        const fetchData = async ()=> {
            try {
                const response = await CourseReview.get("/");
                setCourses(response.data.data.courses);
        } catch (err) {console.log(err)}
        };
        
        fetchData();
           // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleCourseSelect = (data) => {
        history.push(`/courses/${data.data.id}`)
    }

    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'specialty', headerName: 'Specialty', flex: 0.5,},
        { field: 'average_rating', 
        renderCell: (el)=>(
            !el.value ? `No ratings yet` :
            <>
            <StarRating rating = {el.value} size = "small"/>
		({el.row.count})
		</>
        ),
        headerName: 'Average Rating', 
        flex: 0.5 },
      ];
      

      const [open, setOpen] = useState(true);

      
    const phoneCheck = () => {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return (
            <div style={{
                marginBottom: '2rem'
            }}>
          <Collapse in={open}>
      <Alert 
      variant="standard"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      severity="error"
      >
          It looks like you're on a mobile device. 
      Hold your phone in landscape mode to best view the table!</Alert>
      </Collapse>
    </div>
        )
    }
}

     

    return (
        <div className="container"
        style={{width:'100%'}}>
            {phoneCheck()}
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid rows={courses} columns={columns}  
            autoHeight 
            showToolbar
            hideFooterPagination	
            onRowSelected ={(e)=>handleCourseSelect(e)}
            />
        </div>
        </div>

    )
}

export default BrowseAllCourses
