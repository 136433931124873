import React from 'react'
import Header from '../components/Header';

const NotFound = () => {
    return (
        <div style={{
            textAlign: 'center'
        }}>
            <Header/>
            Sorry page not found.
            <h1 style={{
                fontSize: '30vh',
                marginTop: '2rem'
            }}>
                404
            </h1>
        </div>
    )
}

export default NotFound
