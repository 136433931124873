import React, {useEffect,useContext } from 'react';
import CourseReview from "../apis/CourseReview";
import { CoursesContext } from '../context/CoursesContext';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';

const CourseList = (props) => {
    const {courses, setCourses} = useContext(CoursesContext);
    
    let history = useHistory();
    useEffect(() => {
        const fetchData = async ()=> {
            try {
                const response = await CourseReview.get("https://physiocr.ca/api/v1/courses/");
                setCourses(response.data.data.courses);
        } catch (err) {console.log(err)}
        };
        
        fetchData();
           // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleDelete = async (e, id) => {
        e.stopPropagation()
        try {
           await CourseReview.delete(`https://physiocr.ca/api/v1/courses/${id}`)
           setCourses(courses.filter(el => {
               return el.id !== id
           }))
        //    console.log(response)
        } catch (err) {
            console.log(err)
        }

    }

    const handleUpdate = (e, id) => {
        e.stopPropagation()
        history.push(`/courses/${id}/update`)
    }

    const handleReviewEdit = (e, id) => {
        e.stopPropagation()
        // console.log(e)
        // console.log(id)
        history.push(`/courses/${id}/editreviews`)
    }

    const handleCourseSelect = (id) => {
        history.push(`/courses/${id}`)
    }
    return (
        <div>

<TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Course</TableCell>
            {/* <TableCell align="center">Location</TableCell>
            <TableCell align="center">Price</TableCell>*/}
            <TableCell align="center">Specialty</TableCell> 
            <TableCell align="center">Rating</TableCell> 
            <TableCell align="center">Edit Reviews</TableCell> 
            <TableCell align="center">Update</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {courses.map((course) => (
            <TableRow 
            onClick={() => handleCourseSelect(course.id)}
            key={course.id}>
              <TableCell component="th" scope="row">
                {course.name}
              </TableCell>
              {/* <TableCell align="right">{course.location}</TableCell>
              <TableCell align="right">{course.price_range}</TableCell> */}
              <TableCell align="center">{course.specialty}</TableCell>
              <TableCell align="center">{course.average_rating}</TableCell>
              <TableCell align="center">
                  <Button
                  onClick={ (e) => handleReviewEdit(e, course.id)}
                  size="small"
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}>
                      Edit
                  </Button>
                </TableCell>
              <TableCell align="center">
              <Button
                    onClick={ (e) => handleUpdate(e, course.id)}
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<UpdateIcon />}>
                Update
                </Button>
                </TableCell>
                <TableCell align="center">
                <Button
                    onClick={ (e) => handleDelete(e, course.id)}
                    size="small"
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}>
                Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            
            
        </div>
    )
}

export default CourseList
